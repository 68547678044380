<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <div class="separator">
        <p class="breadcrumb-menu">
          <router-link :to="{ name: 'Reminders', query: this.createSearchQuery() }" class="ashen-link">リマインダー管理
          </router-link>
          <i class="fas fa-angle-right form-control-color"></i>リマインダー情報
        </p>
      </div>
      <div class="card border-0 text-first mt-3">
        <div class="row d-flex justify-content-between">
          <div v-if="prevReminderString" class="col-6">
            <router-link :to="linkPrevReminder" class="ashen-link mx-1" replace>
              <i class="fas fa-angle-left"></i>
              {{ prevReminderString }}
            </router-link>
          </div>
          <div v-else class="col-6"></div>
          <div v-if="nextReminderString" class="col-6 text-end">
            <router-link :to="linkNextReminder" class="ashen-link mx-1" replace>
              {{ nextReminderString }}<i class="fas fa-angle-right mx-1"></i>
            </router-link>
          </div>
          <div v-else class="col-6"></div>
        </div>
      </div>
    </div>

    <div class="card text-first mt-3">
      <div class="card-header bg-transparent">
        <img src="../assets/images/racket-gray.png"/>
        <span class="mx-3">{{ tournamentName }}</span>
      </div>
      <div class="card-body mt-4 mb-4 px-5">
        <div class="row">
          <div class="col">
            <label for="exampleInputPassword1">タイトル</label>
            <input v-model="reminder.reminderTitle" class="form-control half-width-input mt-1" type="text">
            <div v-show="errors['reminderTitle']" class="form-error">{{ errors['reminderTitle'] }}</div>
          </div>
        </div>
        <div class="row mt-4 dob-section p-l-0">
          <label>時刻</label>
          <div class="d-inline-flex mt-1">
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">時</div>
              <select id="inputGroupSelect02" v-model="reminder.hour" class="form-control">
                <option v-for="hour in hours" :value="hour">
                  {{ hour < 10 ? '0' + hour : hour }}
                </option>
              </select>
            </div>
            <div v-show="errors['hour']" class="form-error">{{ errors['hour'] }}</div>
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">分</div>
              <select id="inputGroupSelect02" v-model="reminder.minute" class="form-control">
                <option v-for="minute in minutes" :value="minute">
                  {{ minute < 10 ? '0' + minute : minute }}
                </option>
              </select>
            </div>
            <div v-show="errors['minute']" class="form-error">{{ errors['minute'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <label>送信先</label>
            <select v-model="reminder.sendTargetId" class="form-control destination form-select mt-1" disabled>
              <option :value="Number(1)">関係者全員</option>
            </select>
            <div v-show="errors['sendTargetId']" class="form-error">{{ errors['sendTargetId'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">件名</label>
            <input v-model="reminder.subject" class="form-control half-width-input mt-1" type="text">
            <div v-show="errors['subject']" class="form-error">{{ errors['subject'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label>本文</label>
            <textarea v-model="reminder.body" class="form-control" cols="50" rows="10"></textarea>
            <div v-show="errors['body']" class="form-error">{{ errors['body'] }}</div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-4">
            <div class="btn-width width-160">
              <Modal :back-to-root="backToReminder"
                     :data="reminder"
                     :reload-data="getData"
                     :reminder-delete="deleteReminder"
                     button-title="削除"
                     cancel-title="キャンセル"
                     confirmation-msg=" を削除してもよろしいですか？ "
                     ok-title="削除" title="削除"></Modal>
            </div>
          </div>
          <div class="col-8 text-end">
            <button class="btn btn-light bg-white width-160 ashen-link text-decoration-none light border-0 btn-width"
                    @click="backToReminder">
              キャンセル
            </button>
            <button class="btn col btn-primary width-160 btn-outline-light font-weight-bold btn-width float-end"
                    @click="updateReminder">変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "../assets/jsadminclient/common";
import Helper from "../assets/jsadminclient/helper";
import {mapGetters} from "vuex";
import Modal from '../components/modal/Modal'

export default {
  name: "ReminderDetail",
  components: {Modal},
  props: {
    tournamentId: {type: [Number, String], default: null},
    reminderId: {type: [Number, String], default: null}
  },
  data() {
    return {
      loading: true,
      tournamentList: {},
      tournamentName: '',
      hours: [...Array(24).keys()],
      minutes: [...Array(60).keys()].filter(item => item % 5 === 0),
      nextReminderId: '',
      nextReminderName: '',
      prevReminderId: '',
      prevReminderName: '',
      reminder: {},
      reloadKey: 1,
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
    prevReminderString() {
      return this.prevReminderName ? this.prevReminderName + '(' + this.prevReminderId + ')' : ''
    },
    nextReminderString() {
      return this.nextReminderName ? this.nextReminderName + '(' + this.nextReminderId + ')' : ''
    },
    linkPrevReminder() {
      return {
        name: "ReminderDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          reminderId: this.prevReminderId,
        },
        query: this.createSearchQuery()
      }
    },
    linkNextReminder() {
      return {
        name: "ReminderDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          reminderId: this.nextReminderId,
        },
        query: this.createSearchQuery()
      }
    },
  },
  watch: {
    $route(to, from) {
      // Called from back or next
      this.getReminder()
    }
  },
  mounted() {
    this.initialPageLoadHandler()
  },
  methods: {
    initialPageLoadHandler() {
      this.getReminder()
      this.getTournament()
    },
    backToReminder() {
      this.$router.push({name: "Reminders", query: this.createSearchQuery()});
    },
    async getReminder() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.reminder = await api.tournamentsTournamentIdRemindersReminderIdGetAsync(
            this.tournamentId,
            this.reminderId,
            this.$route.query.sort,
            this.$route.query.order,
        );

        this.nextReminderId = this.reminder.nextReminderId
        this.nextReminderName = this.reminder.nextReminderName
        this.prevReminderId = this.reminder.prevReminderId
        this.prevReminderName = this.reminder.prevReminderName

      } catch (e) {
        this.reminder = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getData() {
      try {
        this.loading = true;
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdRemindersGetAsync(
            this.tournamentId,
            this.response.current_page,
            this.response.per_page,
            this.response.sort,
            this.response.order
        )

        if (result) {
          this.loading = false
          // this.reloadKey += 1
          this.response = Helper.formatResponse(result)
          // map user data
          this.response.data = result.items.map(function (item) {
            return {
              reminderId: item.reminderId,
              reminderTitle: item.reminderTitle,
              time: item.hour + ':' + item.minute,
              subject: item.subject,
              body: item.body,
              sendTargetId: item.sendTargetId
            }
          })
        }
      } catch (e) {
        this.response.data = [];
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async updateReminder() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdRemindersReminderIdPostAsync(
            this.tournamentId,
            this.reminderId,
            this.reminder
        )
        Common.showToast("リマインダーの情報が変更されました。")
        this.backToReminder()
        this.getData()
      } catch (e) {
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'})
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async deleteReminder() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdRemindersReminderIdDeleteAsync(
            this.tournamentId,
            this.reminderId,
        );
        Common.showToast("リマインダーを削除しました。")
        if (result) {
          this.loading = false
        }
      } catch (e) {
        this.loading = false;
        this.backToReminder()

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },

    async getTournament() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsListTournamentIdGetAsync(
            this.tournamentId,
        );
        this.tournamentName = result.tournamentName
      } catch (e) {
        this.tournamentName = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

textarea.form-control {
  min-height: calc(1.6em + 1.75rem + 160px) !important;
}

.delete {
  width: 160px;
  height: 44px;
}

.destination.form-control {
  background-color: #F8F8F8 !important;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.form-check {
  padding-left: 33px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

::placeholder {
  color: #888888;
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 24%;
  margin-right: 16px;

  &:first-child {
    width: 24%;
  }

  &:last-child {
    width: 24%;
    margin-right: 0px !important;
  }
}

.filter-select,
.filter-search-form,
.btn-search {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  border: 1px solid #dee2e6;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.content {
  &.touradminsip {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }
}
</style>